<template>
  <h-card p0>
    <b-row class="mx-0">
      <b-col class="px-0">
        <b-tabs
          class="custom-tabs"
          content-class="mt-3"
          no-nav-style
          active-nav-item-class="item-custom-active item-tab-sub"
        >
          <b-tab
            title="Mon abonnement"
            class="w-100"
            title-item-class="title-tab-sub"
          >
            <b-row class="px-2" v-if="currentCampaign && loaded">
              <b-col cols="12">
                <b-row class="align-items-center">
                  <b-col
                    md="2"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="@/assets/img/logo-hubcast.png"
                      alt="logo hubcast"
                    />
                  </b-col>
                  <b-col md="5">
                    <div>
                      <h4 v-if="hasSubscribed">
                        Abonné
                      </h4>
                      <h4 v-else>
                        Période d'essai en cours :
                        <b>{{ getTrialPeriodRemains() }} jours</b>
                        restants
                      </h4>
                      <p>Depuis le {{ startSubscriptionDate }}</p>
                    </div>
                    <div class="mt-2">
                      <h4>Votre offre préférentielle</h4>
                      <p v-if="serviceFee">
                        <b>Accès aux services myHubcast</b>
                        <br />
                        <span class="text-primary">
                          {{ `${serviceFee}€` }}
                        </span>
                        par mois, facturation {{ facturationPeriod }}
                      </p>
                      <p v-if="locationFee && locationFee > 0">
                        <b>Location du boitier</b>
                        <br />
                        <span class="text-primary">{{
                          `${locationFee}€`
                        }}</span>
                        par mois
                      </p>
                    </div>
                  </b-col>
                  <b-col md="5" class="d-flex flex-column align-items-center">
                    <h-button
                      v-if="!hasSubscribed"
                      purple
                      @click="displayPaiement = true"
                    >
                      Souscrire et prolonger mes services
                    </h-button>
                    <h-button grey outline class="mt-4">
                      Être rappelé par un commercial
                    </h-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="px-2" v-else>
              <b-col cols="12">
                <div
                  class="py-4 d-flex justify-content-center align-items-center"
                >
                  <span
                    class="spinner-grow spinner-grow-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <div>
                    Récupération de vos informations d'abonnement
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <!-- <b-tab title="Mes factures" title-item-class="title-tab-sub">
            <b-row class="px-2">
              <b-col>Factures</b-col>
            </b-row>
          </b-tab> -->
        </b-tabs>
      </b-col>
    </b-row>
    <ModalSubscriptionPaiement
      v-if="currentCampaign && displayPaiement"
      :showPaiementModal="displayPaiement"
      :currentCampaign="currentCampaign"
      :amount="totalFees"
      :stripeCustomer="stripeCustomer"
      @close="displayPaiement = false"
    />
  </h-card>
</template>

<script>
import campaignService from '../services/campaignService'
import stripeService from '@/services/stripeService'
import { mapState } from 'vuex'
import ModalSubscriptionPaiement from '@/components/modals/ModalSubscriptionPaiement'

export default {
  components: {
    ModalSubscriptionPaiement,
  },
  data() {
    return {
      loaded: false,
      hasCampaign: false,
      currentCampaign: null,
      displayPaiement: false,
      serviceFee: null,
      locationFee: null,
      hasSubscribed: false,
    }
  },
  async mounted() {
    await this.getCampaign()
  },
  methods: {
    async getCampaign() {
      const campaign = await campaignService.getSiteCampaign(
        this.userBrand,
        this.selectedSite.id
      )
      if (campaign.data && campaign.data[0]) {
        this.hasCampaign = true
        this.$store.commit('campaign/SET_CAMPAIGN', campaign.data[0])
        this.currentCampaign = campaign.data[0]
        this.serviceFee = this.currentCampaign.subscriptionCampaignPaymentDetail.calculatedPrice
        this.locationFee = this.currentCampaign.playerAmountHandfee

        if (this.currentCampaign.cSiteCol[0].campState === 5) {
          this.hasSubscribed = true
          this.loaded = true
        } else this.createOrderAndValidCampaign()
      } else {
        this.hasCampaign = false
        this.loaded = true
      }
    },
    async createOrderAndValidCampaign() {
      if (this.$route.query.setup_intent) {
        if (this.currentCampaign.cSiteCol[0].campState !== 5) {
          try {
            await this.postWarehouse()
            const stripeInfo = this.stripeCustomer
            const params = {
              CampState: 5,
              IdBrand: this.campaign.idBrand,
              IdSite: this.campaign.cSiteCol[0].idSite,
              IdSubscriptionCampaign: this.campaign.id,
              Owner: this.campaign.cSiteCol[0].owner,
              StripeInfos: stripeInfo,
              SubscriptionDate: this.campaign.cSiteCol[0].subscriptionDate,
              X3Infos: this.campaign.cSiteCol[0].x3Infos,
            }
            this.hasSubscribed = true
            await campaignService.editCampaign(params)
            this.loaded = true
          } catch (e) {
            alert('Erreur de création de commande')
          }
        }
      } else this.loaded = true
    },
    async postWarehouse() {
      const stripeInfo = this.stripeCustomer
      await stripeService.postWarehouse(
        this.selectedSite.id,
        this.userBrand,
        stripeInfo,
        this.currentCampaign.code
      )
    },
    diffdate(d1, d2) {
      let WNbJours = d2.getTime() - d1.getTime()
      return Math.ceil(WNbJours / (1000 * 60 * 60 * 24))
    },
    getTrialPeriodRemains() {
      const today = new Date()
      const subscriptionDate = this.$moment(
        this.currentCampaign.cSiteCol[0].subscriptionDate
      ).format('YYYY/MM/DD')
      const subscriptionDateFormat = new Date(subscriptionDate)

      const diffSubscriptionDate =
        this.diffdate(subscriptionDateFormat, today) - 1

      const trialPeriode = this.currentCampaign.trialDurationDays
      const remaningTrialPeriod = trialPeriode - diffSubscriptionDate
      return remaningTrialPeriod
    },
  },
  computed: {
    startSubscriptionDate() {
      const date = this.currentCampaign.cSiteCol[0].subscriptionDate
      const momentFormat = this.$moment(date).format('DD/MM/YYYY')
      return momentFormat
    },
    totalFees() {
      const total =
        this.serviceFee + (this.locationFee > 0 ? this.locationFee : 0)
      return total
    },
    facturationPeriod() {
      const period = this.$store.state.campaign.campaign
        .subscriptionCampaignPaymentDetail.libPayment
      const facturationPeriods = {
        MENS: 'mensuelle',
        TRIM: 'trimestrielle',
        SEM: 'semestrielle',
        ANU: 'annuelle',
      }

      return facturationPeriods[period]
    },
    ...mapState({
      selectedSite: (state) => state.user.selectedSite,
      userBrand: (state) => state.user.user.visibleBrands[0].id,
      campaign: (state) => state.campaign.campaign,
      stripeCustomer: (state) => state.stripe.customerId,
    }),
  },
}
</script>

<style lang="scss" scoped>
h4 {
  font-size: 20px;
  margin-bottom: 5px !important;
}
</style>
